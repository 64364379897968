'use client'

import { Spinner } from 'components/Spinner'
import { SmallArrow } from 'components/icons/Arrow'
import { useRef } from 'react'

export function StandaloneTextInput(props: {
  id: string
  placeholder?: string
  onSubmit(value: string): void
  loading?: boolean
  required?: boolean
  type?: string
}) {
  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault()
        props.onSubmit(inputRef.current?.value ?? '')
      }}
    >
      <span className="w-full flex gap-8 items-center justify-items-stretch border border-dashed focus-within:border-solid border-current">
        <input
          id={props.id}
          ref={inputRef}
          className="h-48 p-16 pr-4 outline-none text-16 font-regular placeholder:text-current tracking-32 bg-transparent flex-grow"
          placeholder={props.placeholder}
          required={props.required}
          type={props.type ?? 'text'}
        />
        <span className="justify-self-end p-16 pl-4">
          {props.loading ? (
            <Spinner />
          ) : (
            <button aria-label="submit">
              <SmallArrow className="text-12" />
            </button>
          )}
        </span>
      </span>
    </form>
  )
}
