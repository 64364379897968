import styles from './Toast.module.css'

export default function Toast(props: { message?: string }) {
  if (!props.message) {
    return null
  }

  return (
    <section key={props.message} className={styles.toast}>
      {props.message}
    </section>
  )
}
