import React from 'react'

type GlobalError = Error

export function useFetch<Payload, Error>(options?: {
  onSuccess?: (res: Response) => void
  onError?: (res: Response) => void
}): {
  fetch(url: string, init?: RequestInit): Promise<Response>
  data: Payload | undefined
  error: Error | GlobalError | undefined
  loading: boolean
} {
  const [state, setState] = React.useState<State<Payload, Error>>({
    state: 'initial',
  })

  async function modifiedFetch(...args: FetchArgs) {
    setState({ state: 'loading' })

    try {
      const response = await fetch(...args)
      const body = await response.json()

      if (response.ok) {
        setState({ state: 'successful', payload: body })
        options?.onSuccess?.(response)
      } else {
        setState({ state: 'error', error: body })
      }

      return response
    } catch (error: any) {
      setState({ state: 'error', error })
      throw error
    }
  }

  return {
    fetch: modifiedFetch,
    data: state.state === 'successful' ? state.payload : undefined,
    error: state.state === 'error' ? state.error : undefined,
    loading: state.state === 'loading',
  }
}

type FetchArgs = Parameters<typeof fetch>

type State<Payload, Error> =
  | {
      state: 'initial'
    }
  | {
      state: 'successful'
      payload: Payload
    }
  | {
      state: 'loading'
    }
  | {
      state: 'error'
      error: Error
    }
