'use client'
import { trackGoal } from 'lib/analytics'
import { useFetch } from 'lib/useFetch'
import Toast from './Toast'
import { StandaloneTextInput } from './input/StandaloneTextInput'
import { useLocale, useTranslations } from 'next-intl'

export function NewsletterSingupForm() {
  const { fetch, data, loading, error } = useFetch<string, string>({
    onSuccess() {
      trackGoal('signup:newsletter')
    },
  })
  const locale = useLocale()
  const t = useTranslations('footer')

  return (
    <>
      {!data && (
        <StandaloneTextInput
          id="newsletter-signup"
          placeholder={t('yourEmailAddress')}
          onSubmit={async (value) =>
            fetch('/api/subscription', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                email: value,
                locale,
              }),
            })
          }
          loading={loading}
          required
          type="email"
        />
      )}
      {data && <p>{t('thanksForYourAttention', { name: data })}</p>}
      {error && (
        <Toast message={error instanceof Error ? error.message : error} />
      )}
    </>
  )
}
